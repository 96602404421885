@media (max-width: 992px) {
  .steps_row {
    flex: 1;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    & ul {
      & li {
        &.steps_box {
          flex-grow: 1;
          flex-basis: 250px;
          flex-shrink: 0;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .sidecontentmain {
    margin-left: 0px;
  }
  .sidebarbox {
    left: -250px;
    transition: all 0.3s;
    &.active {
      left: 0px;
      transition: all 0.3s;
    }
  }
  .place_textbox {
    width: 200px;
  }
  .steps_row {
    & ul {
      & li {
        &.steps_box {
          flex-basis: 200px;
        }
      }
    }
  }
  .pro-sidebar.md.collapsed {
    left: 0;
  }
  .form-label {
    min-height: auto;
  }
  .sidecontentmain.active {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .admintopright svg {
    font-size: 11px;
  }
  .accordion_div {
    & .accordion {
      & .accordion_item {
        & .accordion_header {
          display: block;
          & .accordion_header_title {
            margin-bottom: 15px;
            & h5 {
              font-size: 16px;
            }
          }
          & .accordion_header_buttons {
            margin-left: 0px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        & .accordion_body {
          padding-top: 15px;
        }
      }
    }
  }
  .sidecontent {
    border-radius: 10px;
  }
  .chartbox canvas {
    height: 300px;
  }

  .thankyou_box h5,
  .thankyou_box .h5 {
    font-size: 22px;
  }
  .thankyou_box h6 span,
  .thankyou_box .h6 span,
  .thankyou_box h6 b,
  .thankyou_box .h6 b {
    display: block;
  }
  .thankyou_box h6,
  .thankyou_box .h6 {
    font-size: 16px;
  }
  .thankyou_box i {
    font-size: 70px;
  }
  .otpbox input {
    width: 60px !important;
  }
  .admintopright li:not(:last-child) {
    margin-right: 0.5rem;
  }
  .adminlanguagechangediv {
    width: 160px;
    & select {
      font-size: 13px;
    }
  }
  .adminheader {
    padding: 0px 10px;
  }
  .admintopright img {
    height: 30px;
    width: 30px;
  }
  .themetoggle {
    & label {
      width: 40px;
      height: 20px;
      font-size: 12px;
    }
    & span {
      width: 14px;
      height: 14px;
    }
    & input[type="checkbox"]:checked + label span {
      transform: translateX(17px);
    }
  }
  .adminfooter p {
    font-size: 12px;
  }
  .sidemenuheader {
    padding: 5px 0;
  }
  .sidemenuheader img {
    height: 40px;
  }
}

@media (max-width: 400px) {
  .adminlanguagechangediv {
    width: 100px;
  }
}
