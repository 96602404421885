$primary: #224b8b;
$secondary_light: #fbfbfb;
$font: "Poppins", sans-serif;
$min-contrast-ratio:   3.5 !default;


:root {
  --white: white;
  --black: black;
  --box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.rounded-4 {
  border-radius: 0.6rem !important;
}
.rounded-5 {
  border-radius: 0.8rem !important;
}
.fw-medium {
  font-weight: 500;
}

@import "bootstrap";
