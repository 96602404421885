.sidebarbox {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: $primary;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 0.3s;
  &::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .sidebarheader {
    padding: 20px 0;
    text-align: center;
  }
  & .sidebarmenu {
    & nav {
      & > ul {
        & > li {
          padding: 5px 15px;
          & > a,
          button {
            display: flex;
            width: 100%;
            color: #fff;
            padding: 5px 0px;
            align-items: center;
            background-color: transparent;
            border: none;
            text-align: left;
            font-size: 14px;
            justify-content: center;
            border-radius: 5px;
            & .menuicon {
              flex-shrink: 0;
              width: 40px;
              text-align: center;
              font-size: 18px;
              & svg {
                color: white;
              }
            }
            & .menutext {
              flex-grow: 1;
              margin-left: 10px;
            }
            &:hover{
              background: rgb(255 255 255 / 20%);
            }
          }
        }
        & li.submenuli {
          & .submenudropdown {
            & > li {
              padding: 5px 0;
              & > a,
              button {
                display: flex;
                width: 100%;
                color: #fff;
                padding: 5px 0px;
                align-items: center;
                background-color: transparent;
                border: none;
                text-align: left;
                font-size: 13px;
                justify-content: center;
                border-radius: 5px;
                & .menuicon {
                  flex-shrink: 0;
                  width: 40px;
                  text-align: center;
                  border-radius: 5px;
                  font-size: 16px;
                  padding: 4px 0;
                  & svg {
                    color: white;
                  }
                }
                & .menutext {
                  flex-grow: 1;
                  margin-left: 15px;
                }
                &:hover{
                  background:rgb(255 255 255 / 20%);
                }
              }
            }
          }
        }
      }
    }
  }

  &.active {
    transition: all 0.3s;
    & .sidebarmenu {
      & nav {
        & > ul {
          & > li {
            padding: 5px 10px;
            & > a,
            button {
              padding: 5px 15px;
              & .menuicon {
                width: auto;
              }
            }
          }
          & li.submenuli {
            & .submenudropdown {
              & > li {
                padding: 5px 15px;
                & > a,
                button {
                  padding: 5px 15px;
                  & .menuicon {
                    width: auto;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.hover {
    transition: all 0.3s;
    & .sidebarmenu {
      & nav {
        & > ul {
          & > li {
            padding: 5px 10px;
            & > a,
            button {
              padding: 5px 15px;
              & .menuicon {
                width: auto;
              }
            }
          }
          & li.submenuli {
            & .submenudropdown {
              & > li {
                padding: 5px 15px;
                & > a,
                button {
                  padding: 5px 15px;
                  & .menuicon {
                    width: auto;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%);
  z-index: 1;
  display: none;
  opacity: 0;
  visibility: hidden;
  &.show {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 767px) {
  .sidebar_overlay {
    display: none !important;
  }
}
