.feedbacksec {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logobox {
  img {
    height: 100px;
  }
}
.star_rating_box {
  text-align: center;
  .star-ratings {
    display: flex !important;
    justify-content: center;
    gap:10px;
    .star-container {
      background-color: aliceblue;
      padding: 10px 0 !important;
      border-radius: 5px;
      width:50px;
    }
  }
}
